
































import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import RepositoryFactory from '@/services/RepositoryFactory';
import IBoxInventoryTemplateRepository from '@/services/Repositories/boxInventoryTemplate/types';

const factory = new RepositoryFactory();

export default Vue.extend({
  name: 'InventoryTemplates',
  components: {
    TheFabButton: () => import('@/components/ui/TheFabButton.vue'),
  },
  data() {
    return {
      templates: [] as any[],
    };
  },
  methods: {
    ...mapMutations('configuration', ['CLEAR_STATES', 'SET_NEWINVENTORYTEMPLATE']),
    async getInventoryTemplates() {
      const data = await this.boxInventoryTemplateRepository.get();
      const hydraMember = data.data['hydra:member'];
      this.templates = hydraMember;
    },
    handleStartNewInventoryTemplate() {
      this.CLEAR_STATES();
      this.$router.push({ name: 'CreateInventoryTemplate' });
    },
    handleStartEditInventoryTemplate(id: string) {
      this.CLEAR_STATES();
      this.SET_NEWINVENTORYTEMPLATE(false);
      this.$router.push({ name: 'EditInventoryTemplate', params: { id } });
    },
  },
  created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      title: 'inventory-templates.inventory-templates',
      icons: { icon: icons.back, action: actions.goBack },
      page: { name: 'configurationIndex' },
    });
    this.getInventoryTemplates();
  },
  computed: {
    ...mapState('globals', ['loader']),
    boxInventoryTemplateRepository(): IBoxInventoryTemplateRepository {
      return factory.get('boxInventoryTemplates') as IBoxInventoryTemplateRepository;
    },
  },
});
